import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../images/logo-white.png";

const Navbar = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("sin");
  const [page, setPage] = useState("home");

  useEffect(() => {
    console.log(page);
  }, [page]);

  useEffect(() => {
    setPage(localStorage.getItem("page"));
  }, [localStorage.getItem("page")]);

  const handleChangeLanguage = (lang) => {
    console.log(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    setSelectedLang(localStorage.getItem("i18nextLng"));
  }, []);

  useEffect(() => {
    setSelectedLang(localStorage.getItem("i18nextLng"));
  }, [localStorage.getItem("i18nextLng")]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light"
        id="ftco-navbar"
        style={{
          backgroundColor: `${page === "credits" ? "#252525" : "transparent"}`,
        }}
      >
        <div className="container">
          {/* <a className="navbar-brand" href="/">
            YDS PPA
          </a> */}
          <img
            src={logo}
            alt="yatagala-ppa-logo"
            width={50}
            style={{ borderRadius: "50%" }}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> Menu
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a
                  href="/"
                  className={`nav-link ${
                    selectedLang === "sin" ? "sin-font" : ""
                  }`}
                  style={{
                    color: `${
                      page === "home" ? "#d5ba7a" : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Home")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/gallery"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "gallery"
                        ? "#d5ba7a"
                        : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Gallery")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/about"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "about" ? "#d5ba7a" : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("AboutUs")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/projects"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "projects"
                        ? "#d5ba7a"
                        : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Projects")}
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="/donate" className="nav-link">
                  {t("Donate")}
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  href="/event"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "events" ? "#d5ba7a" : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Events")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/careers"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "careers"
                        ? "#d5ba7a"
                        : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Careers")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/blog"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "blog" ? "#d5ba7a" : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("RecentBlogs")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/contact"
                  className="nav-link"
                  style={{
                    color: `${
                      page === "contact"
                        ? "#d5ba7a"
                        : "rgba(255, 255, 255, 0.8)"
                    }`,
                  }}
                >
                  {t("Contact")}
                </a>
              </li>
              <li className="nav-item ">
                <select
                  className="nav-item-language-selector"
                  onChange={(e) => handleChangeLanguage(e.target.value)}
                >
                  <option
                    value={"en"}
                    selected={selectedLang === "en" ? true : false}
                  >
                    English
                  </option>
                  <option
                    value={"sin"}
                    selected={selectedLang === "sin" ? true : false}
                  >
                    Sinhala
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
