import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Fade } from "react-reveal";

const projects = [
  {
    title:
      "දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා ආදි ශිශ්‍ය අනුග්‍රහය දැක්විම - 2024",
    image: "images/projects/pq4.jpg",
    description:
      "22 වන වරටත් සංවිධානය වූ දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා අනුග්‍රහයක් දක්වමින්, අවුරුදු සමයට ප්‍රයෝජනවත් වන ලෙසින් රුපියල් පනස්දහසකට ආසන්න වටිනාකමකින් යුත් රු.2,000 බැගින් වූ ත්‍යාග වවුචරපත් තරඟයන්හි ප්‍රථම ස්ථානයන් හිමිකරගත් ජයග්‍රාහකයන් වෙත පිරිනමන්නට අපට හැකි වූයෙමු... තනි තනිව නොව, සමූහයක් වශයෙන් එක්ව සිට ගනිමින් දහම් පාසල වෙත ලබා දෙන ශක්තියේ විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "අවැසි මොහොතේ අස්වැසිල්ලක් වූයෙමු...",
    image: "images/projects/pa14.jpg",
    description:
      "රට තුල පවතින Covid-19 වසංගත තත්වය හමුවේ ජන ජීවිතයට එල්ල වූ බලපෑම් මත, ආදි ශිෂ්‍ය ශිෂ්‍යාවන්ගේ සහ පරිත්‍යාගශීලීන්ගේ ද දායකත්වයෙන් යටගල රජමහ විහාර දහම් පාසල අවට ග්‍රාමසේවා වසම් වල ජීවත් වන අඩු ආදායම්ලාභී පවුල් 220ක් වෙත වියලි ආහාර ද්‍රව්‍ය බෙදා දීමට හැකි විය...",
    path: "",
  },
  {
    title: '"අවැසි මොහොතේ නැවත අස්වැසිල්ලක් වූයෙමු" දෙවන අදියර',
    image: "images/projects/pn9.jpg",
    description:
      "පවතින තත්වය හමුවේ සරණක් සොයන අපේම මිනිසුන් උදෙසා, ග්‍රාම සේවා වසම් හතක අඩු ආදායම්ලාභී පවුල් 160 ක් වෙනුවෙන් සංවිධානය වූ අපගේ සත්කාරය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015",
    image: "images/projects/pb4.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014",
    image: "images/projects/pc7.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title:
      "උතුම් වූ උපසම්පදාව ලැබ වැඩම වූ ගරුතර හිමිවරුන් දෙපළ වෙත දැක් වූ අපගේ පූජෝපහාරය",
    image: "images/projects/pd7.jpg",
    description:
      "උපසම්පදා පුන්‍ය මහෝත්සවයේ උපසම්පදාලාභී ස්වාමීන් වහන්සේලා දෙපළ පිලිගැනීම වෙනුවෙන් අත්‍යවශ්‍යව පැවති පිලිගැනීමේ තොරණ ආදි ශිෂ්‍ය සංගමයේ අනුග්‍රහයෙන් නිර්මාණය කරන්නට අප හට හැකි වූයෙමු...දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
];

const Projects = () => {
  const { t } = useTranslation();

  const limitString = (str, limit) => {
    return str.length > limit ? str.slice(0, limit) + "..." : str;
  };
  // const [projects, setProjects] = useState([]);

  const options = {
    items: 3,
    loop: true,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  // const fetchProjects = () => {
  //   try {
  //     axios
  //       .get("https://ppa-dashboard.onrender.com/api/v1/projects")
  //       .then((response) => {
  //         // console.log(response.data);
  //         const list = response.data;
  //         setProjects(...projects, list);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   console.log(projects);
  // }, [projects]);

  useEffect(() => {
    localStorage.setItem("page", "projects");
    // fetchProjects();
  }, []);
  return (
    <>
      <div
        className="hero-wrap"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/projects/pm13.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  className="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span className="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Projects")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  className="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("Projects")}
                </h1>
                <p className="project-para">{t("ProjectPageDesc")}</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section projects-section">
        <div className="container d-flex flex-column">
          <div className="row">
            <Fade fraction={0.2} duration={2000}>
              <div
                className={`careers-grid-container d-flex align-items-stretch ${
                  projects.length < 3
                    ? `justify-content-start`
                    : `justify-content-center`
                }`}
              >
                {Array.isArray(projects) &&
                  projects.map((project, index) => {
                    return (
                      <div
                        className="item d-flex career-grid-item flex-grow-1"
                        style={{
                          padding: "15px",
                        }}
                        key={index}
                      >
                        <div className="cause-entry align-self-stretch w-100 d-flex flex-column flex-grow-1">
                          <a
                            href="#"
                            className="img"
                            style={{
                              backgroundImage: `url(${project.image})`,
                              height: "200px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></a>
                          <div className="text d-flex flex-column p-3 p-md-4 justify-content-between flex-grow-1">
                            <h3>
                              <a
                                href="#"
                                style={{
                                  textDecoration: "none",
                                  fontSize: "1.3rem",
                                }}
                              >
                                {project.title}
                              </a>
                            </h3>
                            <p>{limitString(project.description, 150)}</p>
                            <a
                              className="project-read-more d-flex"
                              href="/article"
                              onClick={() => {
                                localStorage.setItem("article", "projects");
                                localStorage.setItem("index", index);
                              }}
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};
export default Projects;
