import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Fade, Zoom } from "react-reveal";
import swirl from "../images/abstract.png";
import axios from "axios";

const vacancies = [
  {
    id: "",
    title: "Sales Executive",
    image: "images/jmc.jpg",
    description:
      "Job type: Full Time<br/>Salary per month: Rs 45,000 - 90,000<br/>Required education: Ordinary Level<br/>Required work experience (years): 1<br/>Job Description: Any diploma or certificates in sales field, Good communication skills and interpersonal skills.<br/><br/>Generated sales leads and met monthly sales targets.<br/>Developed business relationships with customers and identified sales opportunities.<br/>Negotiated pricing and terms with customers.<br/>Maintained accurate records of sales and customer data.<br/>Collaborated with marketing to craft effective sales materials.",
  },
];

export default function Careers() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cv, setCv] = useState(null);
  const [position, setPosition] = useState("");
  // const [vacancies, setVacancies] = useState([]);
  const [openJobForm, setOpenJobForm] = useState(false);

  useEffect(() => {
    localStorage.setItem("page", "careers");
    // fetchVacancies();
  }, []);

  // useEffect(() => {
  //   console.log(vacancies);
  // }, [vacancies]);

  const submitResume = () => {
    try {
      if (
        name !== "" &&
        address !== "" &&
        telephone !== "" &&
        cv !== null &&
        position !== ""
      ) {
        toast.success("Your resume submitted successfully !");
        setName("");
        setAddress("");
        setTelephone("");
        setPosition("");
        setCv(null);
      } else if (name === "") {
        toast.error("Name is required !");
      } else if (address === "") {
        toast.error("Address is required !");
      } else if (telephone === "") {
        toast.error("Telephone is required !");
      } else if (cv === null) {
        toast.error("Resume is required !");
      } else if (position === "") {
        toast.error("Expected position is required !");
      }
    } catch (error) {
      toast.error("An error occured while submitting your resume !");
      console.log(error);
    }
  };

  // const fetchVacancies = () => {
  //   try {
  //     axios
  //       .get("https://ppa-dashboard.onrender.com/api/v1/vacancies")
  //       .then((response) => {
  //         const list = response.data;
  //         setVacancies(...vacancies, list);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {openJobForm && (
        <div className="modal-background">
          <section className="ftco-section modal-section">
            <div className="d-flex container flex-column">
              <Fade fraction={0.2} duration={2000}>
                <div className="joblink-form popup p-5 m-0 position-relative">
                  <button
                    className="joblink-close-btn"
                    onClick={() => setOpenJobForm(false)}
                  >
                    X
                  </button>
                  <div className="joblink-form-wrapper">
                    <div className="joblink-form-left d-flex flex-column align-items-start">
                      <p className="name-title">{t("EnterYourName")}</p>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p className="address-title">{t("EnterYourAddress")}</p>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <p className="address-title">{t("ExpectedPosition")}</p>
                      <input
                        type="text"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </div>
                    <div className="joblink-form-right d-flex flex-column align-items-start">
                      <p className="telephone-title">
                        {t("EnterYourTelephone")}
                      </p>
                      <input
                        type="number"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                      />
                      <p className="resume-title">{t("EnterYourResume")}</p>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => setCv(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <button
                    className="joblink-apply-btn d-flex mt-5"
                    onClick={() => submitResume()}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </Fade>
            </div>
          </section>
        </div>
      )}
      <div
        class="hero-wrap"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/careers_bg_1.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  class="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span class="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Career")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  class="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("Careers")}
                </h1>
                <p className="project-para">{t("JobLinkDesc")}</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section d-flex flex-column">
        <div className="row justify-content-center mb-5 pb-3 w-100">
          <Zoom fraction={0.2} duration={2000}>
            <div className="col-md-7 heading-section  text-center">
              <h2 className="mb-4 title-row">
                <img src={swirl} alt="swirl" />
                {t("Vacancies")}
                <img src={swirl} alt="swirl" />
              </h2>
            </div>
          </Zoom>
        </div>
        <div className="container d-flex flex-column">
          <div className="row">
            <Fade fraction={0.2} duration={2000}>
              <div
                className={`careers-grid-container d-flex ${
                  vacancies.length < 3
                    ? `justify-content-start`
                    : `justify-content-center`
                }`}
              >
                {Array.isArray(vacancies) && vacancies.length !== 0 ? (
                  vacancies.map((vacancy, index) => {
                    return (
                      <div
                        className="item d-flex career-grid-item"
                        // style={{ padding: "15px", flexGrow: 1 }}
                        key={index}
                      >
                        <div className="cause-entry">
                          <a
                            href="#"
                            className="img"
                            style={{
                              backgroundImage: `url(${vacancy.image})`,
                            }}
                          ></a>
                          <div className="text p-3 p-md-4">
                            <h3 className="text-start w-100">
                              <a href="#" style={{ textDecoration: "none" }}>
                                {vacancy.title}
                              </a>
                            </h3>
                            <p
                              className="text-start w-100"
                              dangerouslySetInnerHTML={{
                                __html: vacancy.description,
                              }}
                            />
                            <a
                              className="careers-apply d-flex"
                              href={vacancy.path}
                              onClick={() => setOpenJobForm(true)}
                            >
                              Apply
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No vacancies available right now !</p>
                )}
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="ftco-section">
        <div className="d-flex container flex-column">
          <Fade fraction={0.2} duration={2000}>
            <div className="joblink-form desktop p-5 m-0 position-relative">
              <div className="joblink-form-wrapper">
                <div className="joblink-form-left d-flex flex-column align-items-start">
                  <p className="name-title">{t("EnterYourName")}</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="address-title">{t("EnterYourAddress")}</p>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <p className="address-title">{t("ExpectedPosition")}</p>
                  <input
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
                <div className="joblink-form-right d-flex flex-column align-items-start">
                  <p className="telephone-title">{t("EnterYourTelephone")}</p>
                  <input
                    type="number"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                  <p className="resume-title">{t("EnterYourResume")}</p>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setCv(e.target.files[0])}
                  />
                </div>
              </div>
              <button
                className="joblink-apply-btn d-flex mt-5"
                onClick={() => submitResume()}
              >
                {t("Submit")}
              </button>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
}
