import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const upcomingEvents = [
  {
    title: "යටගල දහම් පාසල් සුභසාධක වෙළෙදසැල",
    image: "images/IMG-20241005-WA0031.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      "යටගල රජමහ විහාර දහම් පාසල වෙත දහම් අධ්‍යාපනය හැදෑරීම සඳහා පැමිණෙන දරුවන් වෙනුවෙන් ඔවුන්ගේ සුභසාධනයට කටයුතු කිරීම අප මෙයින් ප්‍රධාන වශයෙන් බලාපොරොත්තු වේ. එහිදී දරුවන්ට අවශ්‍ය වන දහම් අධ්‍යාපනය සඳහා අවශ්‍ය වන අධ්‍යාපනික උපකරණ වෙළඳපල මිලටත් වඩා සහනදායී ආකාරයට දහම් පාසල් දරුවන්ට ලබා දීම මෙමඟින් සිදුවේ. මෙම කර්තව්‍ය මගින් විශාල ආර්ථික අපහසුතා ඇති දරුවන්ට පවා යම් සහනයක් ලබාදීම අපගේ ප්‍රධාන අරමුණ වේ. යටගල රජමහ විහාර දහම් පාසලට දහම් අධ්‍යාපනයට පැමිණෙන සියළු දුවා දරුවන් රැක බලා ගැනීම, ඔවුන් ගැන සොයා බැලීම අපගේ වගකීමක් ලෙස අප සිතන්නෙමු. එහිදී දහම් අධ්‍යාපනයට පැමිණෙන දරුවන්ට යම් ආර්ථික අපහසුතා ඇතිවන්නේ නම් එමගින් ඔවුන්ගේ අධ්‍යාපනයට දැඩි බාධාවක් සිදුවන්නේ නම් එය මගහරවා ගැනීමට, යම් දායකත්වයක් ලබාදීම අප මෙමගින් අපේක්ෂා කරන්නෙමු.",
    path: "",
  },
  {
    title: "යටගල රාජමහා විහාර වෙසක් මහා දන්සල",
    image: "images/projects/pb3.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      'යටගල රාජමහා විහාරය යනු ශ්‍රී ලංකාව පුරා විසිරී පැතිරී සිටින බෞද්ධ ජනතාවගේ වන්දනාමානයට පත්වන ඓතිහාසික මෙන්ම ආකර්ශනීය පුදබිමකි. මෙම පුදබිම වසරේ සෑම පොහෝ දිනයකම බැතිමතුන්ගෙන් පිරී ඉතිරී යන අතර, විශේෂයෙන්ම වෙසක් පුන් පොහෝ දිනය යනු යටගල රාජමහා විහාරය අති විශාල බැතිමතුන් පිරිසකගෙන් පිරී ඉතිරී යන පොහොයකි. විහාරය වැඳ පුදා ගැනීමට දිවයිනේ නන් දෙසින් පැමිණෙන බැතිමතුන් වෙනුවෙන් වසර ගණනාවක් මුළුල්ලේ සිට අප විසින් සංවිධානය කරනු ලැබූ "යටගල රාජමහා විහාර වෙසක් මහා දන්සල" විශාල පිරිසකගේ කුසගිනි නිවන්නට ඉවහල් වූ සුවිශේෂී කටයුත්තකි. පසුගිය අවධියෙහි මෙරට සිදුවූ යම් යම් සිද්ධීන් හේතුවෙන් මෙම කටයුත්ත අපහට සිදුකිරීමට බාධා පැමිණි අතර මෙම මහගු පුණ්‍යකර්මය 2025 වර්ෂයේ වෙසක් පුන් පොහෝ දින සිට ඉදිරියට ක්‍රියාත්මක කිරීමට අප කටයුතු සූදානම් කර ඇත.',
    path: "",
  },

  {
    title: "දම් දරු සුරක්ෂා",
    image: "images/IMG-20241005-WA0038.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      "යටගල රජමහ විහාර දහම් පාසලට දහම් අධ්‍යාපනයට පැමිණෙන සියළු දුවා දරුවන් රැක බලා ගැනීම, ඔවුන් ගැන සොයා බැලීම අපගේ වගකීමක් ලෙස අප සිතන්නෙමු. එහිදී දහම් අධ්‍යාපනයට පැමිණෙන දරුවන්ට යම් ආර්ථික අපහසුතා ඇතිවන්නේ නම් එමගින් ඔවුන්ගේ අධ්‍යාපනයට දැඩි බාධාවක් සිදුවන්නේ නම් එය මගහරවා ගැනීමට, යම් දායකත්වයක් ලබාදීම අප මෙමගින් අපේක්ෂා කරන්නෙමු.",
    path: "",
  },
];

const UpcomingEvents = () => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [eventName, setEventName] = useState("");

  const limitString = (str, limit) => {
    return str.length > limit ? str.slice(0, limit) + "..." : str;
  };
  // const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   console.log(events);
  // }, [events]);

  // const fetchEvents = () => {
  //   try {
  //     axios
  //       .get("https://ppa-dashboard.onrender.com/api/v1/events")
  //       .then((response) => {
  //         const list = response.data;
  //         setEvents(...events, list);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    localStorage.setItem("page", "events");
    // fetchEvents();
  }, []);

  const applyEvent = () => {
    try {
      if (name !== "" && address !== "" && eventName !== "") {
        toast.success("Your request was sent successfully !");
        setName("");
        setAddress("");
        setEventName("");
      } else if (name === "") {
        toast.error("Name is required !");
      } else if (address === "") {
        toast.error("Address is required !");
      } else if (eventName === "") {
        toast.error("Event name is required !");
      }
    } catch (error) {
      toast.error("An error occured while submitting your request !");
      console.log(error);
    }
  };

  const options = {
    items: 3,
    loop: true,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {openForm && (
        <div className="modal-background">
          <section className="ftco-section modal-section">
            <div className="d-flex container flex-column">
              <Fade fraction={0.2} duration={2000}>
                <div className="joblink-form popup p-5 m-0 position-relative">
                  <button
                    className="joblink-close-btn"
                    onClick={() => setOpenForm(false)}
                  >
                    X
                  </button>
                  <div className="joblink-form-wrapper">
                    <div className="joblink-form-left d-flex flex-column align-items-start">
                      <p className="name-title">{t("EnterYourName")}</p>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p className="address-title">{t("EnterYourAddress")}</p>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="joblink-form-right d-flex flex-column align-items-start">
                      <p className="telephone-title">{t("EnterEvent")}</p>
                      <input
                        type="text"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="joblink-apply-btn d-flex mt-5"
                    onClick={() => applyEvent()}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </Fade>
            </div>
          </section>
        </div>
      )}
      <div
        className="hero-wrap"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/projects/pa14.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  className="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span className="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Events")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  className="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("UpcomingEvents")}
                </h1>
                <p className="project-para">{t("EventPageDesc")}</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section bg-light">
        <div className="container">
          <Fade fraction={0.2} duration={1000}>
            {/* <p className="project-para">{t("EventPageDesc")}</p> */}
          </Fade>
          <div className="row">
            <Fade fraction={0.2} duration={2000}>
              <div
                className={`careers-grid-container d-flex align-items-stretch ${
                  upcomingEvents.length < 3
                    ? `justify-content-start`
                    : `justify-content-center`
                }`}
              >
                {Array.isArray(upcomingEvents) &&
                  upcomingEvents.map((event, index) => {
                    return (
                      <div className="item d-flex career-grid-item flex-grow-1">
                        <div
                          className="blog-entry align-self-stretch w-100 h-100"
                          key={index}
                        >
                          <a
                            href="blog-single.html"
                            className="block-20"
                            style={{
                              backgroundImage: ` url(${event.image})`,
                            }}
                          ></a>
                          <div className="text p-4 d-block">
                            <div className="meta mb-3">
                              <div>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  {event.date}
                                  {/* {new Date(
                                    event.createdAt
                                  ).toLocaleDateString()} */}
                                </a>
                              </div>
                              <div>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Admin
                                </a>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="meta-chat"
                                  style={{ textDecoration: "none" }}
                                >
                                  <span className="icon-chat"></span> 3
                                </a>
                              </div>
                            </div>
                            <h3 className="heading mb-4">
                              <a href="#" style={{ textDecoration: "none" }}>
                                {event.title}
                              </a>
                            </h3>
                            <p className="time-loc">
                              <span className="mr-2">
                                <i className="icon-clock-o"></i> {event.time}
                                {/* {new Date(event.createdAt).toLocaleTimeString()} */}
                              </span>{" "}
                              <span>
                                <i className="icon-map-o"></i> Venue{" "}
                                {event.venue}
                              </span>
                            </p>
                            <p>{limitString(event.description, 150)}</p>
                            <p>
                              <a
                                className="join-event-btn"
                                href="#"
                                onClick={() => setOpenForm(true)}
                              >
                                Join Event{" "}
                                <i className="ion-ios-arrow-forward"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};
export default UpcomingEvents;
