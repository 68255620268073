import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import swirl from "../images/abstract.png";
import StaticMap from "../components/StaticMap";
import toast, { Toaster } from "react-hot-toast";
import { Fade, Zoom } from "react-reveal";

const Contact = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    localStorage.setItem("page", "contact");
  }, []);

  const sendMesssage = () => {
    try {
      if (name !== "" && email !== "" && subject !== "" && message !== "") {
        toast.success("Your message delivered successfully !");
        setEmail("");
        setName("");
        setSubject("");
        setMessage("");
      } else if (name === "") {
        toast.error("Name is required !");
      } else if (email === "") {
        toast.error("Email is required !");
      } else if (subject === "") {
        toast.error("Subject is required !");
      } else if (message === "") {
        toast.error("Message is required !");
      }
    } catch (error) {
      toast.error("An error occured while delivering your message !");
      console.log(error);
    }
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div
        class="hero-wrap"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/IMG-20241004-WA0041.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  class="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span class="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Contact")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  class="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("ContactUs")}
                </h1>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section class="ftco-section contact-section ftco-degree-bg">
        <div class="container">
          <div class="row d-flex mb-5 contact-info">
            <div class="col-md-12 mb-4">
              <Zoom duration={2000} fraction={0.5}>
                <h2 className="mb-4 title-row">
                  <img src={swirl} alt="swirl" />
                  {t("ContactTitle")}
                  <img src={swirl} alt="swirl" />
                </h2>
              </Zoom>
            </div>
            <div class="w-100"></div>

            <div class="col-md-3">
              <p>
                <span>Address:</span> Yatagala Raja Maha Viharaya 27G6+4F2,
                Unawatuna, Galle
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span>Phone:</span>{" "}
                <a href="tel://1234567920">+9471 571 0777</a>
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span>Email:</span>{" "}
                <a href="mailto:info@yoursite.com">
                  info@yatagaladhammaschoolppa.com
                </a>
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span>Website</span>{" "}
                <a href="https://yatagaladhammaschoolppa.com/" target="_blank">
                  yatagaladhammaschoolppa.com
                </a>
              </p>
            </div>
          </div>

          <div
            class="row block-9 d-flex contact-container"
            style={{ display: "flex" }}
          >
            <div class="col p-0 contact-left">
              <h4 class="mb-4">{t("ContactSubtitle")}</h4>
              <form action="#">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder={t("YourName")}
                    value={name}
                    onChange={(e) => setName()}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder={t("YourEmail")}
                    value={email}
                    onChange={(e) => setEmail()}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder={t("Subject")}
                    value={subject}
                    onChange={(e) => setSubject()}
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    class="form-control"
                    placeholder={t("Message")}
                    value={message}
                    onChange={(e) => setMessage()}
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="button"
                    value={t("SendMessage")}
                    class="btn btn-primary py-3 px-5"
                    onClick={() => sendMesssage()}
                  />
                </div>
              </form>
            </div>

            <div class="col p-0 contact-right" id="map">
              <StaticMap />
            </div>
          </div>

          {/* <div class="row block-9 d-flex">
            <div class="col pr-md-5">
              <h4 class="mb-4">Do you have any questions?</h4>
              <form action="#">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Email"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    class="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-primary py-3 px-5"
                  />
                </div>
              </form>
            </div>

            <div
              class="col p-0"
              id="map"
              style={{ display: "flex", flexGrow: 1 }}
            >
              <StaticMap />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Contact;
