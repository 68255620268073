import { useEffect } from "react";
import swirl from "../images/abstract.png";

export default function Credits() {
  useEffect(() => {
    localStorage.setItem("page", "credits");
  }, []);
  return (
    <div className="credits-container d-flex flex-column justify-content-center align-items-center">
      <div className="col-md-5 heading-section  text-center">
        <h2 className="mb-4  title-row">
          <img src={swirl} alt="swirl" />
          Developer
          <img src={swirl} alt="swirl" />
        </h2>
      </div>
      <h1>K.L. Kavinda Himesh Gunathilaka</h1>
      <p>Senior Software Engineeer at Loopy Holdings.</p>
      <p>2024</p>
    </div>
  );
}
