import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import swirl from "../images/abstract.png";
import { Bounce, Fade, Flip, Roll, Rotate, Slide, Zoom } from "react-reveal";

const team = [
  {
    name: "Naveen Dasas",
    image: "url(images/person_1.jpg)",
    position: "President of Yatagala Dhamma School Past Pupils Association",
  },
  {
    name: "Shakindu Manawadu",
    image: "url(images/person_2.jpg)",
    position: "Secretary of Yatagala Dhamma School Past Pupils Association",
  },
  {
    name: "Sathira Kushan",
    image: "url(images/person_3.jpg)",
    position: "Treasurer of Yatagala Dhamma School Past Pupils Association",
  },
];

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem("page", "about");
  }, []);
  return (
    <>
      <div
        className="hero-wrap"
        style={{
          // background: "transparent",
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/IMG-20241004-WA0035.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  className="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span className="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("AboutUs")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  className="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("AboutUs")}
                </h1>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section">
        <div className="container">
          <div className="row d-flex">
            <Zoom fraction={0.5} duration={2000}>
              <div className="col-md-6 d-flex ">
                <div
                  className="img img-about align-self-stretch"
                  style={{
                    backgroundImage: "url(images/IMG-20241005-WA0029.jpg)",
                    backgroundPosition: "center",
                    width: " 100%",
                  }}
                ></div>
              </div>
            </Zoom>
            <div className="col-md-6 pl-md-5 ">
              <Fade duration={2000}>
                <h2 className="mb-4">{t("Welcome")}</h2>
              </Fade>
              {/* <p>{t("WelcomeContent")}</p> */}
              <Fade duration={2000} delay={500}>
                <div
                  dangerouslySetInnerHTML={{ __html: t("WelcomeContent") }}
                ></div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ftco-counter ftco-section ftco-intro ftco-intro-2 overflow-hidden"
        id="section-counter"
      >
        <div className="container">
          <div className="row no-gutters">
            <Slide left duration={2000} fraction={0.5}>
              <div className="col-md d-flex justify-content-center counter-wrap ">
                <div className="block-18 color-1 align-items-stretch">
                  <div className="text">
                    <span>{t("MemberCount")}</span>
                    <strong className="number" data-number="1432805">
                      150+
                    </strong>
                    <span>{t("MemberDesc")}</span>
                  </div>
                </div>
              </div>
            </Slide>
            {/* <div className="col-md d-flex justify-content-center counter-wrap ">
              <div className="block-18 color-2 align-items-stretch">
                <div className="text">
                  <h3 className="mb-4">Donate Money</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts.
                  </p>
                  <p>
                    <a href="#" className="btn btn-white px-3 py-2 mt-2">
                      Donate Now
                    </a>
                  </p>
                </div>
              </div>
            </div> */}
            <Slide right duration={2000} fraction={0.5}>
              <div className="col-md d-flex justify-content-center counter-wrap ">
                <div className="block-18 color-3 align-items-stretch">
                  <div className="text">
                    <h3 className="mb-4">{t("BeAVolunteer")}</h3>
                    <p>{t("BeAVolunteerDesc")}</p>
                    <p>
                      <a
                        href="https://main.d3g26xs5mwaic5.amplifyapp.com/"
                        className="btn btn-white px-3 py-2 mt-2 text-uppercase"
                        target="_blank"
                      >
                        {t("JoinUs")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </section>

      {/* <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section  text-center">
              <Zoom fraction={0.5} duration={2000}>
                <h2 className="mb-4 title-row">
                  <img src={swirl} alt="swirl" />
                  {t("OurTeam")}
                  <img src={swirl} alt="swirl" />
                </h2>
                <p>{t("OurTeamDesc")}</p>
              </Zoom>
            </div>
          </div>
          <Fade duration={2000} fraction={0.5}>
            <div className="row">
              {team.map((member, index) => {
                return (
                  <div className="col-lg-4 d-flex mb-sm-4 team-wrapper">
                    <div className="staff" key={index}>
                      <div className="d-flex mb-4">
                        <div
                          className="img"
                          style={{ backgroundImage: member.image }}
                        ></div>
                        <div className="info ml-4">
                          <h3>
                            <a
                              href="teacher-single.html"
                              className="text-decoration-none"
                            >
                              {member.name}
                            </a>
                          </h3>
                          <span className="position">{member.position}</span>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fade>
        </div>
      </section> */}
    </>
  );
};
export default About;
