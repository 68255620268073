import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Fade, Slide } from "react-reveal";

const blogs = [
  {
    title:
      "2024 වර්ෂයට සැමට සෙත් පතා පවත්වනු ලැබූ වාර්ෂික පහන් පූජා පිංකම සහ ආශිර්වාද පූජාව",
    image: "images/projects/pm6.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    date: "Sep. 10, 2024",
  },
  {
    title:
      "යටගල රජමහ විහාර දහම් පාසල් ආදි සිසු එකමුතුවෙහි දෙනව මහා සභා රැස්වීම 2019",
    image: "images/projects/ps6.jpg",
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක පෙර මග සලකුණු තබන්නට යුතුකම් ඉටු කරන්නට දම් පියස වෙත රොද බැඳි ඔබ සියළු දෙනා හට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම",
    image: "images/projects/pf3.jpg",
    description:
      "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම... එකමුතුව ශක්තිමත් කරන්නට පැමිණි ඔබ සැමට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමය මහා සභා රැස්වීම සහ නිලවරණය - 2024",
    image: "images/projects/pt2.jpg",
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක අභිමානය තව තවත් ශක්තිමත් කිරීමට, දම් පියස වෙත තම යුතුකම ඉටු කරන්නට පැමිණි සැමට ස්තූතියි.",
    date: "Sep. 10, 2024",
  },
];

const Blog = () => {
  const { t } = useTranslation();
  // const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    localStorage.setItem("page", "blog");
    // fetchEvents();
  }, []);

  // useEffect(() => {
  //   console.log(blogs);
  // }, [blogs]);

  const options = {
    items: 3,
    loop: true,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  // const fetchEvents = () => {
  //   try {
  //     axios
  //       .get("https://ppa-dashboard.onrender.com/api/v1/blogs")
  //       .then((response) => {
  //         const list = response.data;
  //         setBlogs(...blogs, list);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <div
        class="hero-wrap"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/blog_bg_2.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  class="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span class="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("RecentBlogs")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  class="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("RecentBlogs")}
                </h1>
                <p className="project-para">{t("BlogPageDesc")}</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section class="ftco-section">
        <div class="container d-flex flex-column">
          <Fade fraction={0.2} duration={1000}></Fade>
          <div className="row d-flex g-0">
            <Fade fraction={0.5} duration={2000}>
              <div
                className={`careers-grid-container d-flex align-items-stretch ${
                  blogs.length < 3
                    ? `justify-content-start`
                    : `justify-content-center`
                }`}
              >
                {Array.isArray(blogs) &&
                  blogs.map((blog, index) => {
                    return (
                      <div
                        className="item d-flex career-grid-item flex-grow-1"
                        key={index}
                      >
                        <div className="blog-entry align-self-stretch w-100 d-flex flex-column flex-grow-1">
                          <a
                            href="blog-single.html"
                            className="block-20"
                            style={{
                              backgroundImage: `url(${blog.image})`,
                              height: "200px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></a>
                          <div className="text p-4 d-flex flex-column justify-content-between flex-grow-1">
                            <div className="meta mb-3">
                              <div>
                                <a href="#">{blog.date}</a>
                              </div>
                              <div>
                                <a href="#">Admin</a>
                              </div>
                              <div>
                                <a href="#" className="meta-chat">
                                  <span className="icon-chat"></span> 3
                                </a>
                              </div>
                            </div>
                            <h3 className="heading mt-3">
                              <a href="#">{blog.title}</a>
                            </h3>
                            <p>{blog.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fade>
          </div>
          {/* <div class="row d-flex">
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_1.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_2.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_3.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_4.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_5.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex">
              <div class="blog-entry align-self-stretch">
                <a
                  href="blog-single.html"
                  class="block-20"
                  style={{ backgroundImage: "url('images/image_6.jpg')" }}
                ></a>
                <div class="text p-4 d-block">
                  <div class="meta mb-3">
                    <div>
                      <a href="#">Sept 10, 2018</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                    <div>
                      <a href="#" class="meta-chat">
                        <span class="icon-chat"></span> 3
                      </a>
                    </div>
                  </div>
                  <h3 class="heading mt-3">
                    <a href="#">Hurricane Irma has devastated Florida</a>
                  </h3>
                  <p>
                    A small river named Duden flows by their place and supplies
                    it with the necessary regelialia.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="row mt-5">
            <div class="col text-center">
              <div class="block-27">
                <ul>
                  <li>
                    <a href="#">&lt;</a>
                  </li>
                  <li class="active">
                    <span>1</span>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">&gt;</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Blog;
