import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import logo from "../images/yatagala-black-logo.png";
import team from "../images/team_3.jpg";
import swirl from "../images/abstract.png";
import swirlWhite from "../images/abstract-white.png";
import { useEffect, useState } from "react";
import { Bounce, Fade, Flip, Roll, Rotate, Slide, Zoom } from "react-reveal";
import pen from "../images/feather-pen.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const projects = [
  {
    title:
      "දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා ආදි ශිශ්‍ය අනුග්‍රහය දැක්විම - 2024",
    image: "images/projects/pq4.jpg",
    description:
      "22 වන වරටත් සංවිධානය වූ දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා අනුග්‍රහයක් දක්වමින්, අවුරුදු සමයට ප්‍රයෝජනවත් වන ලෙසින් රුපියල් පනස්දහසකට ආසන්න වටිනාකමකින් යුත් රු.2,000 බැගින් වූ ත්‍යාග වවුචරපත් තරඟයන්හි ප්‍රථම ස්ථානයන් හිමිකරගත් ජයග්‍රාහකයන් වෙත පිරිනමන්නට අපට හැකි වූයෙමු... තනි තනිව නොව, සමූහයක් වශයෙන් එක්ව සිට ගනිමින් දහම් පාසල වෙත ලබා දෙන ශක්තියේ විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "අවැසි මොහොතේ අස්වැසිල්ලක් වූයෙමු...",
    image: "images/projects/pa14.jpg",
    description:
      "රට තුල පවතින Covid-19 වසංගත තත්වය හමුවේ ජන ජීවිතයට එල්ල වූ බලපෑම් මත, ආදි ශිෂ්‍ය ශිෂ්‍යාවන්ගේ සහ පරිත්‍යාගශීලීන්ගේ ද දායකත්වයෙන් යටගල රජමහ විහාර දහම් පාසල අවට ග්‍රාමසේවා වසම් වල ජීවත් වන අඩු ආදායම්ලාභී පවුල් 220ක් වෙත වියලි ආහාර ද්‍රව්‍ය බෙදා දීමට හැකි විය...",
    path: "",
  },
  {
    title: '"අවැසි මොහොතේ නැවත අස්වැසිල්ලක් වූයෙමු" දෙවන අදියර',
    image: "images/projects/pn9.jpg",
    description:
      "පවතින තත්වය හමුවේ සරණක් සොයන අපේම මිනිසුන් උදෙසා, ග්‍රාම සේවා වසම් හතක අඩු ආදායම්ලාභී පවුල් 160 ක් වෙනුවෙන් සංවිධානය වූ අපගේ සත්කාරය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015",
    image: "images/projects/pb4.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014",
    image: "images/projects/pc7.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title:
      "උතුම් වූ උපසම්පදාව ලැබ වැඩම වූ ගරුතර හිමිවරුන් දෙපළ වෙත දැක් වූ අපගේ පූජෝපහාරය",
    image: "images/projects/pd7.jpg",
    description:
      "උපසම්පදා පුන්‍ය මහෝත්සවයේ උපසම්පදාලාභී ස්වාමීන් වහන්සේලා දෙපළ පිලිගැනීම වෙනුවෙන් අත්‍යවශ්‍යව පැවති පිලිගැනීමේ තොරණ ආදි ශිෂ්‍ය සංගමයේ අනුග්‍රහයෙන් නිර්මාණය කරන්නට අප හට හැකි වූයෙමු...දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
];

const upcomingEvents = [
  {
    title: "යටගල දහම් පාසල් සුභසාධක වෙළෙදසැල",
    image: "images/IMG-20241005-WA0031.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      "යටගල රජමහ විහාර දහම් පාසල වෙත දහම් අධ්‍යාපනය හැදෑරීම සඳහා පැමිණෙන දරුවන් වෙනුවෙන් ඔවුන්ගේ සුභසාධනයට කටයුතු කිරීම අප මෙයින් ප්‍රධාන වශයෙන් බලාපොරොත්තු වේ. එහිදී දරුවන්ට අවශ්‍ය වන දහම් අධ්‍යාපනය සඳහා අවශ්‍ය වන අධ්‍යාපනික උපකරණ වෙළඳපල මිලටත් වඩා සහනදායී ආකාරයට දහම් පාසල් දරුවන්ට ලබා දීම මෙමඟින් සිදුවේ. මෙම කර්තව්‍ය මගින් විශාල ආර්ථික අපහසුතා ඇති දරුවන්ට පවා යම් සහනයක් ලබාදීම අපගේ ප්‍රධාන අරමුණ වේ. යටගල රජමහ විහාර දහම් පාසලට දහම් අධ්‍යාපනයට පැමිණෙන සියළු දුවා දරුවන් රැක බලා ගැනීම, ඔවුන් ගැන සොයා බැලීම අපගේ වගකීමක් ලෙස අප සිතන්නෙමු. එහිදී දහම් අධ්‍යාපනයට පැමිණෙන දරුවන්ට යම් ආර්ථික අපහසුතා ඇතිවන්නේ නම් එමගින් ඔවුන්ගේ අධ්‍යාපනයට දැඩි බාධාවක් සිදුවන්නේ නම් එය මගහරවා ගැනීමට, යම් දායකත්වයක් ලබාදීම අප මෙමගින් අපේක්ෂා කරන්නෙමු.",
    path: "",
  },
  {
    title: "යටගල රාජමහා විහාර වෙසක් මහා දන්සල",
    image: "images/projects/pb3.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      'යටගල රාජමහා විහාරය යනු ශ්‍රී ලංකාව පුරා විසිරී පැතිරී සිටින බෞද්ධ ජනතාවගේ වන්දනාමානයට පත්වන ඓතිහාසික මෙන්ම ආකර්ශනීය පුදබිමකි. මෙම පුදබිම වසරේ සෑම පොහෝ දිනයකම බැතිමතුන්ගෙන් පිරී ඉතිරී යන අතර, විශේෂයෙන්ම වෙසක් පුන් පොහෝ දිනය යනු යටගල රාජමහා විහාරය අති විශාල බැතිමතුන් පිරිසකගෙන් පිරී ඉතිරී යන පොහොයකි. විහාරය වැඳ පුදා ගැනීමට දිවයිනේ නන් දෙසින් පැමිණෙන බැතිමතුන් වෙනුවෙන් වසර ගණනාවක් මුළුල්ලේ සිට අප විසින් සංවිධානය කරනු ලැබූ "යටගල රාජමහා විහාර වෙසක් මහා දන්සල" විශාල පිරිසකගේ කුසගිනි නිවන්නට ඉවහල් වූ සුවිශේෂී කටයුත්තකි. පසුගිය අවධියෙහි මෙරට සිදුවූ යම් යම් සිද්ධීන් හේතුවෙන් මෙම කටයුත්ත අපහට සිදුකිරීමට බාධා පැමිණි අතර මෙම මහගු පුණ්‍යකර්මය 2025 වර්ෂයේ වෙසක් පුන් පොහෝ දින සිට ඉදිරියට ක්‍රියාත්මක කිරීමට අප කටයුතු සූදානම් කර ඇත.',
    path: "",
  },
  {
    title: "දම් දරු සුරක්ෂා",
    image: "images/IMG-20241005-WA0038.jpg",
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      "යටගල රජමහ විහාර දහම් පාසලට දහම් අධ්‍යාපනයට පැමිණෙන සියළු දුවා දරුවන් රැක බලා ගැනීම, ඔවුන් ගැන සොයා බැලීම අපගේ වගකීමක් ලෙස අප සිතන්නෙමු. එහිදී දහම් අධ්‍යාපනයට පැමිණෙන දරුවන්ට යම් ආර්ථික අපහසුතා ඇතිවන්නේ නම් එමගින් ඔවුන්ගේ අධ්‍යාපනයට දැඩි බාධාවක් සිදුවන්නේ නම් එය මගහරවා ගැනීමට, යම් දායකත්වයක් ලබාදීම අප මෙමගින් අපේක්ෂා කරන්නෙමු.",
    path: "",
  },
];

const blogs = [
  {
    title:
      "2024 වර්ෂයට සැමට සෙත් පතා පවත්වනු ලැබූ වාර්ෂික පහන් පූජා පිංකම සහ ආශිර්වාද පූජාව",
    image: "images/projects/pm6.jpg",
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    date: "Sep. 10, 2024",
  },
  {
    title:
      "යටගල රජමහ විහාර දහම් පාසල් ආදි සිසු එකමුතුවෙහි දෙනව මහා සභා රැස්වීම 2019",
    image: "images/projects/ps6.jpg",
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක පෙර මග සලකුණු තබන්නට යුතුකම් ඉටු කරන්නට දම් පියස වෙත රොද බැඳි ඔබ සියළු දෙනා හට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම",
    image: "images/projects/pf3.jpg",
    description:
      "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම... එකමුතුව ශක්තිමත් කරන්නට පැමිණි ඔබ සැමට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමය මහා සභා රැස්වීම සහ නිලවරණය - 2024",
    image: "images/projects/pt2.jpg",
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක අභිමානය තව තවත් ශක්තිමත් කිරීමට, දම් පියස වෙත තම යුතුකම ඉටු කරන්නට පැමිණි සැමට ස්තූතියි.",
    date: "Sep. 10, 2024",
  },
];

const Home = () => {
  const { t } = useTranslation();
  const [telephone, setTelephone] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [eventName, setEventName] = useState("");
  const [cv, setCv] = useState(null);
  const [selectedLang, setSelectedLang] = useState("en");
  const navigate = useNavigate();
  const [openForm, setOpenForm] = useState(false);

  const limitString = (str, limit) => {
    return str.length > limit ? str.slice(0, limit) + "..." : str;
  };

  useEffect(() => {
    localStorage.setItem("page", "home");
    setSelectedLang(localStorage.getItem("i18nextLng"));
  }, []);

  useEffect(() => {
    console.log(selectedLang);
  }, [selectedLang]);

  useEffect(() => {
    setSelectedLang(localStorage.getItem("i18nextLng"));
  }, [localStorage.getItem("i18nextLng")]);

  const options = {
    items: 3,
    loop: true,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    console.log(cv);
  }, [cv]);

  const applyEvent = () => {
    try {
      if (name !== "" && address !== "" && eventName !== "") {
        toast.success("Your request was sent successfully !");
        setName("");
        setAddress("");
        setEventName("");
      } else if (name === "") {
        toast.error("Name is required !");
      } else if (address === "") {
        toast.error("Address is required !");
      } else if (eventName === "") {
        toast.error("Event name is required !");
      }
    } catch (error) {
      toast.error("An error occured while submitting your request !");
      console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {openForm && (
        <div className="modal-background">
          <section className="ftco-section modal-section">
            <div className="d-flex container flex-column">
              <Fade fraction={0.2} duration={2000}>
                <div className="joblink-form popup p-5 m-0 position-relative">
                  <button
                    className="joblink-close-btn"
                    onClick={() => setOpenForm(false)}
                  >
                    X
                  </button>
                  <div className="joblink-form-wrapper">
                    <div className="joblink-form-left d-flex flex-column align-items-start">
                      <p className="name-title">{t("EnterYourName")}</p>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p className="address-title">{t("EnterYourAddress")}</p>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="joblink-form-right d-flex flex-column align-items-start">
                      <p className="telephone-title">{t("EnterEvent")}</p>
                      <input
                        type="text"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="joblink-apply-btn d-flex mt-5"
                    onClick={() => applyEvent()}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </Fade>
            </div>
          </section>
        </div>
      )}
      <div
        className="hero-wrap p-0 m-0 d-flex flex-column justify-content-end align-items-center"
        style={{
          backgroundImage:
            " linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/background.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
        data-stellar-background-ratio="0.5"
      >
        {/* <video loop muted autoPlay className="p-0 m-0">
          <source src={buddha} type="video/mp4" />
        </video> */}

        <div className="hero-title-container d-flex flex-column justify-content-end w-100 h-100">
          <Fade duration={2000} delay={500}>
            <h1
              // className="text-start"
              className={`${
                selectedLang === "sin" ? "sin-font sinhala-font" : ""
              } text-start`}
            >
              {t("HomeTitle")}
            </h1>
          </Fade>
          <Fade duration={2000} delay={1000}>
            <h3 className="text-start">{t("HomeSubTitle")}</h3>
          </Fade>
          <Fade duration={2000} delay={1500}>
            <a className="p-0" href="/gallery">
              {t("Exlpore")}
            </a>
          </Fade>
        </div>
        {/* <div className="overlay"></div> */}
        {/* <div className="container">
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <h1
                className="mb-4"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Doing Nothing is Not An Option of Our Life
              </h1>
              <p
                className="mb-5"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Created by{" "}
                <a
                  href="https://www.loopyholdings.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Loopy Holdings.com
                </a>
              </p>

              <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                <a
                  href="https://vimeo.com/45830194"
                  className="btn btn-white btn-outline-white px-4 py-3 popup-vimeo"
                >
                  <span className="icon-play mr-2"></span>Watch Video
                </a>
              </p>
            </div>
          </div>
        </div> */}
      </div>

      {/* <section className="ftco-counter ftco-intro" id="section-counter">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-5 d-flex justify-content-center counter-wrap ">
              <div className="block-18 color-1 align-items-stretch">
                <div className="text">
                  <span>Served Over</span>
                  <strong className="number" data-number="1432805">
                    0
                  </strong>
                  <span>Children in 190 countries in the world</span>
                </div>
              </div>
            </div>
            <div className="col-md d-flex justify-content-center counter-wrap ">
              <div className="block-18 color-2 align-items-stretch">
                <div className="text">
                  <h3 className="mb-4">Donate Money</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts.
                  </p>
                  <p>
                    <a href="#" className="btn btn-white px-3 py-2 mt-2">
                      Donate Now
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md d-flex justify-content-center counter-wrap ">
              <div className="block-18 color-3 align-items-stretch">
                <div className="text">
                  <h3 className="mb-4">Be a Volunteer</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts.
                  </p>
                  <p>
                    <a href="#" className="btn btn-white px-3 py-2 mt-2">
                      Be A Volunteer
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ftco-section">
        <div
          className="m-0 container d-flex flex-column align-items-center justify-content-center"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <div className="col-md-5 heading-section  text-center">
            <Zoom fraction={0.2} duration={2000}>
              <h2 className="mb-5 title-row">
                <img src={swirl} alt="swirl" />
                {t("AboutYatagalaTitle")}
                <img src={swirl} alt="swirl" />
              </h2>
            </Zoom>
          </div>
          <Zoom fraction={0.2} duration={2000}>
            <div className="home-about-section d-flex flex-column align-items-center">
              <img
                src={logo}
                alt="yatagala-dhamma-school-past-pupils'-association"
              />
              <p
                className="p-0"
                dangerouslySetInnerHTML={{ __html: t("AboutYatagala") }}
              />
            </div>
          </Zoom>
        </div>
      </section>

      <section className="ftco-section bg-light">
        <div className="container-fluid">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-5 heading-section  text-center">
              <Zoom fraction={0.2} duration={2000}>
                <h2 className="mb-4  title-row">
                  <img src={swirl} alt="swirl" />
                  {t("WhatWeHaveDone")}
                  <img src={swirl} alt="swirl" />
                </h2>
                <p>{t("ProjectDesc")}</p>
              </Zoom>
            </div>
          </div>
          <div className="row">
            <Fade fraction={0.2} duration={2000}>
              <div className="col-md-12 d-flex">
                <OwlCarousel
                  className="carousel-cause owl-carousel d-flex"
                  {...options}
                >
                  {projects.map((project, index) => {
                    return (
                      <div
                        className="item d-flex flex-grow-1"
                        style={{
                          padding: "15px",
                        }}
                        key={index}
                      >
                        <div className="cause-entry d-flex flex-column flex-grow-1 align-self-stretch">
                          <a
                            href="#"
                            className="img"
                            style={{
                              backgroundImage: `url(${project.image})`,
                              height: "200px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></a>
                          <div className="text p-3 p-md-4 d-flex flex-column justify-content-between flex-grow-1">
                            <h3>
                              <a
                                href="#"
                                style={{
                                  textDecoration: "none",
                                  fontSize: "1.3rem",
                                }}
                              >
                                {project.title}
                              </a>
                            </h3>
                            <p>{limitString(project.description, 150)}</p>
                            <a
                              className="project-read-more d-flex"
                              href="/article"
                              onClick={() => {
                                localStorage.setItem("article", "projects");
                                localStorage.setItem("index", index);
                              }}
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="ftco-apply">
        <div className="container">
          <div className="p-0 m-0 apply-wrapper">
            <Fade fraction={0.2} duration={2000}>
              <div className="p-5 d-flex flex-column align-items-center justify-content-center apply-item">
                <div className="d-flex flex-column">
                  <h1 className="p-0 m-0 title-row align-items-center">
                    <img
                      src={swirlWhite}
                      alt="swirl"
                      style={{ color: "white" }}
                    />
                    {t("ApplyForMembership")}
                    <img
                      src={swirlWhite}
                      alt="swirl"
                      style={{ color: "white" }}
                    />
                  </h1>
                  <p className="p-0 m-0 mt-5 text-center">{t("ApplyDesc")}</p>
                </div>
                <a
                  className="apply-btn d-flex mt-5"
                  href="https://main.d3g26xs5mwaic5.amplifyapp.com/"
                  target="_blank"
                >
                  {t("Apply")}
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <Zoom fraction={0.2} duration={2000}>
              <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4 title-row">
                  <img src={swirl} alt="swirl" />
                  {t("UpcomingEvents")}
                  <img src={swirl} alt="swirl" />
                </h2>
                <p>{t("UpcomingEventsDesc")}</p>
              </div>
            </Zoom>
          </div>
          <div className="row">
            <Fade fraction={0.2} duration={2000}>
              <div className="col-md-12 d-flex">
                <OwlCarousel
                  className="carousel-cause owl-carousel"
                  {...options}
                >
                  {upcomingEvents.map((event, index) => {
                    // return (
                    //   <div
                    //     className="item col-md-4 d-flex flex-grow-1"
                    //     // style={{ minHeight: "650px" }}
                    //   >
                    //     <div
                    //       className="blog-entry align-self-stretch"
                    //       key={index}
                    //     >
                    //       <a
                    //         href="blog-single.html"
                    //         className="block-20"
                    //         style={{ backgroundImage: ` url(${event.image})` }}
                    //       ></a>
                    //       <div className="text p-4 d-flex flex-column justify-content-between flex-grow-1">
                    //         <div className="meta mb-3">
                    //           <div>
                    //             <a href="#" style={{ textDecoration: "none" }}>
                    //               {event.date}
                    //             </a>
                    //           </div>
                    //           <div>
                    //             <a href="#" style={{ textDecoration: "none" }}>
                    //               Admin
                    //             </a>
                    //           </div>
                    //           <div>
                    //             <a
                    //               href="#"
                    //               className="meta-chat"
                    //               style={{ textDecoration: "none" }}
                    //             >
                    //               <span className="icon-chat"></span> 3
                    //             </a>
                    //           </div>
                    //         </div>
                    //         <h3 className="heading mb-4">
                    //           <a href="#" style={{ textDecoration: "none" }}>
                    //             {event.title}
                    //           </a>
                    //         </h3>
                    //         <p className="time-loc">
                    //           <span className="mr-2">
                    //             <i className="icon-clock-o"></i> {event.time}
                    //           </span>{" "}
                    //           <span>
                    //             <i className="icon-map-o"></i> Venue{" "}
                    //             {event.venue}
                    //           </span>
                    //         </p>
                    //         <p>{event.description}</p>
                    //         <p>
                    //           <a className="join-event-btn" href="event.html">
                    //             Join Event{" "}
                    //             <i className="ion-ios-arrow-forward"></i>
                    //           </a>
                    //         </p>
                    //       </div>
                    //     </div>
                    //   </div>
                    // );

                    return (
                      <div className="item col-md-4 d-flex flex-grow-1">
                        <div
                          className="blog-entry align-self-stretch"
                          key={index}
                        >
                          <a
                            href="blog-single.html"
                            className="block-20"
                            style={{
                              backgroundImage: ` url(${event.image})`,
                            }}
                          ></a>
                          <div className="text p-4 d-flex flex-column justify-content-between flex-grow-1">
                            <div className="meta mb-3">
                              <div>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  {event.date}
                                  {/* {new Date(
                                    event.createdAt
                                  ).toLocaleDateString()} */}
                                </a>
                              </div>
                              <div>
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Admin
                                </a>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="meta-chat"
                                  style={{ textDecoration: "none" }}
                                >
                                  <span className="icon-chat"></span> 3
                                </a>
                              </div>
                            </div>
                            <h3 className="heading mb-4">
                              <a href="#" style={{ textDecoration: "none" }}>
                                {event.title}
                              </a>
                            </h3>
                            <p className="time-loc">
                              <span className="mr-2">
                                <i className="icon-clock-o"></i> {event.time}
                                {/* {new Date(event.createdAt).toLocaleTimeString()} */}
                              </span>{" "}
                              <span>
                                <i className="icon-map-o"></i> Venue{" "}
                                {event.venue}
                              </span>
                            </p>
                            <p>{limitString(event.description, 150)}</p>
                            <p>
                              <a
                                className="join-event-btn"
                                href="#"
                                onClick={() => setOpenForm(true)}
                              >
                                Join Event{" "}
                                <i className="ion-ios-arrow-forward"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section className="ftco-section pt-0 bg-light">
        <div className="p-0 mt-0 joblink-container d-flex">
          <div className="joblink-left-container d-flex flex-column p-5 align-items-start">
            <Fade fraction={0.2} duration={2000}>
              <div className="d-flex flex-row joblink-title">
                <h2 className="mb-4 text-start text-white">
                  {/* {t("JobLink")} */}
                  <h1 dangerouslySetInnerHTML={{ __html: t("JobLink") }} />
                </h2>
                {/* <img src={pen} className="ms-3" height={40} alt="pen" /> */}
              </div>
              <p className="text-light text-start mt-3">{t("JobLinkDesc")}</p>
              <a
                className="joblink-apply-btn d-flex flex-row align-items-center mt-5 p-2"
                href="/careers"
              >
                {t("ApplyJob")}
              </a>
            </Fade>
          </div>
          <div className="joblink-right-container"></div>
          {/* <div className="row justify-content-center mb-5 pb-3">
            <Zoom fraction={0.5} duration={2000}>
              <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4 title-row">
                  <img src={swirl} alt="swirl" />
                  {t("JobLink")}
                  <img src={swirl} alt="swirl" />
                </h2>
                <p>{t("JobLinkDesc")}</p>
              </div>
            </Zoom>
          </div> */}
          {/* <Fade fraction={0.8} duration={2000}>
            <div className="joblink-form p-5 m-0">
              <div className="joblink-form-wrapper">
                <div className="joblink-form-left d-flex flex-column align-items-start">
                  <p className="name-title">Enter your name</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="address-title">Enter your address</p>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="joblink-form-right d-flex flex-column align-items-start">
                  <p className="telephone-title">Enter your telephone</p>
                  <input
                    type="number"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                  <p className="resume-title">Enter your resume</p>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setCv(e.target.files[0])}
                  />
                </div>
              </div>
              <button
                className="joblink-apply-btn d-flex mt-5"
                onClick={() => submitResume()}
              >
                {t("Submit")}
              </button>
            </div>
          </Fade> */}
        </div>
      </section>

      <section className="ftco-gallery ftco-section d-flex flex-column">
        <div className="row justify-content-center mb-5 pb-3 w-100">
          <Zoom fraction={0.2} duration={2000}>
            <div className="col-md-7 heading-section  text-center">
              <h2 className="mb-4 title-row">
                <img src={swirl} alt="swirl" />
                {t("AttractionsTitle")}
                <img src={swirl} alt="swirl" />
              </h2>
            </div>
          </Zoom>
        </div>
        <div className="d-md-flex">
          <Zoom duration={1000} delay={500}>
            <a
              href="images/IMG_7225-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_4.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7225-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000} delay={500}>
            {" "}
            <a
              href="images/IMG_7214-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_8.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7214-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000} delay={500}>
            <a
              href="images/IMG_7241-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_7.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7241-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000} delay={500}>
            {" "}
            <a
              href="images/IMG_7218-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_5.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7218-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
        </div>
        <div className="d-md-flex">
          <Zoom duration={1000} delay={500}>
            <a
              href="images/IMG_7237-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_6.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7237-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000}>
            <a
              href="images/IMG_7166-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img"
              // style={{ backgroundImage: "url(images/gallery_1.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7166-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000} delay={500}>
            <a
              href="images/IMG_7283-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img "
              // style={{ backgroundImage: "url(images/gallery_2.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7283-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
          <Zoom duration={1000} delay={500}>
            <a
              href="images/IMG_7180-min.jpg"
              className="gallery image-popup d-flex justify-content-center align-items-center img"
              // style={{ backgroundImage: "url(images/gallery_3.jpg)" }}
            >
              <div
                className="background-image"
                style={{ backgroundImage: "url(images/IMG_7180-min.jpg)" }}
              ></div>
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-search"></span>
              </div>
            </a>
          </Zoom>
        </div>
      </section>

      {/* <section
        class="ftco-section-3 img"
        style={{ backgroundImage: "url(images / bg_3.jpg)" }}
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row d-md-flex">
            <div class="col-md-6 d-flex ">
              <div
                class="img img-2 align-self-stretch"
                style={{ backgroundImage: " url(images/bg_4.jpg)" }}
              ></div>
            </div>
            <div class="col-md-6 volunteer pl-md-5 ">
              <h3 class="mb-3">Be a volunteer</h3>
              <form action="#" class="volunter-form">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Email"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="3"
                    class="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-white py-3 px-5"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <Zoom fraction={0.2} duration={2000}>
              <div className="col-md-7 heading-section blog-section text-center">
                <h2 className="mb-4 title-row">
                  <img src={swirl} alt="swirl" />
                  {t("RecentBlogs")}
                  <img src={swirl} alt="swirl" />
                </h2>
                <p>{t("RecentBlogsDesc")}</p>
              </div>
            </Zoom>
          </div>
          <div className="row d-flex">
            <Fade fraction={0.2} duration={2000}>
              <div className="col-md-12 ">
                <OwlCarousel
                  className="carousel-cause owl-carousel"
                  {...options}
                >
                  {blogs.map((blog, index) => {
                    return (
                      <div
                        className="col-md-4 d-flex"
                        key={index}
                        style={{ minHeight: "650px" }}
                      >
                        <div className="blog-entry align-self-stretch">
                          <a
                            href="blog-single.html"
                            className="block-20"
                            style={{ backgroundImage: `url(${blog.image})` }}
                          ></a>
                          <div className="text p-4 d-flex flex-column justify-content-between flex-grow-1">
                            <div className="meta mb-3">
                              <div>
                                <a href="#">{blog.date}</a>
                              </div>
                              <div>
                                <a href="#">Admin</a>
                              </div>
                              <div>
                                <a href="#" className="meta-chat">
                                  <span className="icon-chat"></span> 3
                                </a>
                              </div>
                            </div>
                            <h3 className="heading mt-3">
                              <a href="#">{blog.title}</a>
                            </h3>
                            <p>{blog.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
