import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en/translation.json";
import sinTranslation from "./locales/sin/translation.json";

i18n
  .use(LanguageDetector) // Automatically detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      sin: {
        translation: sinTranslation,
      },
    },
    fallbackLng: "en", // Default language
    interpolation: {
      escapeValue: false, // React already handles XSS protection
    },
  });

export default i18n;
