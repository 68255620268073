import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Fade, Zoom } from "react-reveal";

const images = [
  "IMG-20241004-WA0015.jpg",
  "IMG-20241004-WA0017.jpg",
  "IMG-20241004-WA0019.jpg",
  "IMG-20241004-WA0020.jpg",
  "IMG-20241004-WA0022.jpg",
  "IMG-20241004-WA0024.jpg",
  "IMG-20241004-WA0026.jpg",
  "IMG-20241004-WA0028.jpg",
  "IMG-20241004-WA0030.jpg",
  "IMG-20241004-WA0031.jpg",
  "IMG-20241004-WA0033.jpg",
  "IMG-20241004-WA0035.jpg",
  "IMG-20241004-WA0037.jpg",
  "IMG-20241004-WA0039.jpg",
  "IMG-20241004-WA0041.jpg",
  "IMG-20241004-WA0043.jpg",
  "IMG-20241004-WA0045.jpg",
  "IMG-20241005-WA0001.jpg",
  "IMG-20241005-WA0003.jpg",
  "IMG-20241005-WA0005.jpg",
  "IMG-20241005-WA0007.jpg",
  "IMG-20241005-WA0009.jpg",
  "IMG-20241005-WA0011.jpg",
  "IMG-20241005-WA0013.jpg",
  "IMG-20241005-WA0015.jpg",
  "IMG-20241005-WA0017.jpg",
  "IMG-20241005-WA0019.jpg",
  "IMG-20241005-WA0020.jpg",
  "IMG-20241005-WA0022.jpg",
  "IMG-20241005-WA0023.jpg",
  "IMG-20241005-WA0025.jpg",
  "IMG-20241005-WA0027.jpg",
  "IMG-20241005-WA0029.jpg",
];

const Gallery = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem("page", "gallery");
  }, []);
  return (
    <>
      <div
        class="hero-wrap"
        style={{
          // background: "transparent",
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('images/gallery_bg_2.jpg')",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div
          class="overlay"
          // style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        ></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  class="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span class="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Gallery")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  class="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("Gallery")}
                </h1>
                <p className="project-para">{t("AttractionsDesc")}</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section ftco-gallery">
        <div className="d-flex p-0 m-0 gallery-grid align-items-center justify-content-center">
          {images.map((image, index) => {
            return (
              <Zoom fraction={0.5} duration={500}>
                <a
                  key={index}
                  href={`images/${image}`}
                  className="gallery gallery-item image-popup d-flex justify-content-center align-items-center img"
                >
                  <div
                    className="background-image"
                    style={{ backgroundImage: `url(images/${image})` }}
                  ></div>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="icon-search"></span>
                  </div>
                </a>
              </Zoom>
            );
          })}
        </div>
      </section>

      {/* <section class="ftco-section ftco-gallery">
        <div class="container">
          <div class="d-md-flex">
            <a
              href="images/cause-2.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/cause-2.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/cause-3.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/cause-3.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/cause-4.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/cause-4.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/cause-5.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/cause-5.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
          </div>
          <div class="d-md-flex">
            <a
              href="images/cause-6.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/cause-6.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/image_3.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/image_3.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/image_1.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/image_1.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/image_2.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/image_2.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
          </div>
          <div class="d-md-flex">
            <a
              href="images/event-1.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/event-1.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/event-2.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/event-2.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/image_1.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/image_1.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
            <a
              href="images/image_2.jpg"
              class="gallery image-popup d-flex justify-content-center align-items-center img"
              style={{ backgroundImage: "url('images/event-4.jpg')" }}
            >
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-search"></span>
              </div>
            </a>
          </div>
        </div>
      </section> */}

      {/* <section
        class="ftco-section-3 img"
        style={{ backgroundImage: "url('images/bg_3.jpg')" }}
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row d-md-flex">
            <div class="col-md-6 d-flex">
              <div
                class="img img-2 align-self-stretch"
                style={{ backgroundImage: "url('images/bg_4.jpg')" }}
              ></div>
            </div>
            <div class="col-md-6 volunteer pl-md-5">
              <h3 class="mb-3">Be a volunteer</h3>
              <form action="#" class="volunter-form">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Email"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="3"
                    class="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-white py-3 px-5"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Gallery;
