import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Projects from "./pages/Projects";
import UpcomingEvents from "./pages/UpcomngEvents";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import Credits from "./pages/Credits";
import Loading from "./pages/Loading";
import { useEffect, useState } from "react";
import Careers from "./pages/Careers";
import Article from "./pages/Article";

function App() {
  const [selectedRoute, setSelectedRoute] = useState("Home");
  useEffect(() => {
    setSelectedRoute(localStorage.getItem("page"));
  }, [localStorage.getItem("page")]);
  return (
    <div
      className="App p-0 m-0"
      // style={{
      //   backgroundImage: `${
      //     selectedRoute === "gallery"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : selectedRoute === "about"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : selectedRoute === "projects"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : selectedRoute === "upcoming-events"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : selectedRoute === "blog"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : selectedRoute === "contact"
      //       ? "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //       : "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('images/gallery_bg_2.jpg')"
      //   }`,
      // }}
    >
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/loading" element={<Loading />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/upcoming-events" element={<UpcomingEvents />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/event" element={<UpcomingEvents />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/article" element={<Article />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
