import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer class="ftco-footer ftco-section img">
        <div class="overlay"></div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2  text-start">{t("AboutUs")}</h2>
                <p className="text-start">
                  Since its establishment in 1938, Yatagala Dhamma School has
                  taught Buddhism and moral principles to numerous generations.
                  In 2019, a group of former students founded the Yatagala
                  Dhamma School Past Pupils Association with the goal of giving
                  back to the school and community.
                </p>
                <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li class="">
                    <a href="#">
                      <span class="icon-twitter"></span>
                    </a>
                  </li>
                  <li class="">
                    <a href="#">
                      <span class="icon-facebook"></span>
                    </a>
                  </li>
                  <li class="">
                    <a href="#">
                      <span class="icon-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 text-start">{t("RecentBlogs")}</h2>
                <div class="block-21 mb-4 d-flex">
                  <div class="text">
                    <h3 class="heading text-start">
                      <a href="#">
                        2024 වර්ෂයට සැමට සෙත් පතා පවත්වනු ලැබූ වාර්ෂික පහන් පූජා
                        පිංකම සහ ආශිර්වාද පූජාව
                      </a>
                    </h3>
                    <div class="meta text-start">
                      <div>
                        <a href="#">
                          <span class="icon-calendar"></span> July 12, 2018
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span class="icon-person"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span class="icon-chat"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block-21 mb-4 d-flex">
                  <div class="text">
                    <h3 class="heading text-start">
                      <a href="#">
                        යටගල රජමහ විහාර දහම් පාසල් ආදි සිසු එකමුතුවෙහි දෙනව මහා
                        සභා රැස්වීම 2019
                      </a>
                    </h3>
                    <div class="meta text-start">
                      <div>
                        <a href="#">
                          <span class="icon-calendar"></span> July 12, 2018
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span class="icon-person"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span class="icon-chat"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="ftco-footer-widget mb-4 ml-md-4">
                <h2 class="ftco-heading-2 text-start">{t("SiteLinks")}</h2>
                <ul class="list-unstyled text-start">
                  <li>
                    <a href="/" class="py-2 d-block text-decoration-none">
                      {t("Home")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/gallery"
                      class="py-2 d-block text-decoration-none"
                    >
                      {t("Gallery")}
                    </a>
                  </li>
                  <li>
                    <a href="/about" class="py-2 d-block text-decoration-none">
                      {t("AboutUs")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/projects"
                      class="py-2 d-block text-decoration-none"
                    >
                      {t("Projects")}
                    </a>
                  </li>
                  <li>
                    <a href="/event" class="py-2 d-block text-decoration-none">
                      {t("Events")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/careers"
                      class="py-2 d-block text-decoration-none"
                    >
                      {t("Careers")}
                    </a>
                  </li>
                  <li>
                    <a href="/blog" class="py-2 d-block text-decoration-none">
                      {t("RecentBlogs")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      class="py-2 d-block text-decoration-none"
                    >
                      {t("Contact")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2  text-start">{t("FaqTitle")}</h2>
                <div class="block-23 mb-3">
                  <ul className="h-auto">
                    <li className="d-flex justify-content-between">
                      <span
                        class="icon icon-map-marker"
                        style={{ width: "20px" }}
                      ></span>
                      <span class="text text-end">
                        Yatagala Raja Maha Viharaya 27G6+4F2, Unawatuna, Galle
                      </span>
                    </li>
                    <li className="d-flex justify-content-between">
                      <span
                        class="icon icon-phone"
                        style={{ width: "20px" }}
                      ></span>
                      <span class="text">+9471 571 0777</span>
                    </li>
                    <li
                      className="d-flex justify-content-between"
                      style={{ height: "100px" }}
                    >
                      <span
                        class="icon icon-envelope"
                        style={{ width: "20px" }}
                      ></span>
                      <span
                        class="text text-end text-wrap"
                        style={{
                          minWidth: "150px",
                          display: "inline-block",
                          wordBreak: "break-word",
                        }}
                      >
                        info@yatagaladhammaschoolppa.com
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                Copyright &copy; {new Date().getFullYear()}. All rights
                reserved. Concept & Developed by{" "}
                <a href="https://loopyholdings.com" target="_blank">
                  Loopy Holdings (Pvt) Ltd.
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
