import React from "react";

const StaticMap = () => {
  return (
    <iframe
      title="Google Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.314711228251!2d80.25856557402533!3d6.025276728754481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae1726240868651%3A0x33507ee674183f2a!2sYatagala%20Raja%20Maha%20Viharaya!5e1!3m2!1sen!2slk!4v1727775365392!5m2!1sen!2slk"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  );
};

export default StaticMap;
