import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Fade, Zoom } from "react-reveal";
import swirl from "../images/abstract.png";

const projects = [
  {
    title:
      "දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා ආදි ශිශ්‍ය අනුග්‍රහය දැක්විම - 2024",
    images: [
      "images/projects/pq1.jpg",
      "images/projects/pq2.jpg",
      "images/projects/pq3.jpg",
      "images/projects/pq4.jpg",
    ],
    description:
      "22 වන වරටත් සංවිධානය වූ දහම් පාසල් අවුරුදු උත්සවයේ ත්‍යාග සඳහා අනුග්‍රහයක් දක්වමින්, අවුරුදු සමයට ප්‍රයෝජනවත් වන ලෙසින් රුපියල් පනස්දහසකට ආසන්න වටිනාකමකින් යුත් රු.2,000 බැගින් වූ ත්‍යාග වවුචරපත් තරඟයන්හි ප්‍රථම ස්ථානයන් හිමිකරගත් ජයග්‍රාහකයන් වෙත පිරිනමන්නට අපට හැකි වූයෙමු... තනි තනිව නොව, සමූහයක් වශයෙන් එක්ව සිට ගනිමින් දහම් පාසල වෙත ලබා දෙන ශක්තියේ විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "අවැසි මොහොතේ අස්වැසිල්ලක් වූයෙමු...",
    images: [
      "images/projects/pa1.jpg",
      "images/projects/pa2.jpg",
      "images/projects/pa3.jpg",
      "images/projects/pa4.jpg",
      "images/projects/pa5.jpg",
      "images/projects/pa6.jpg",
      "images/projects/pa7.jpg",
      "images/projects/pa8.jpg",
      "images/projects/pa9.jpg",
      "images/projects/pa10.jpg",
      "images/projects/pa11.jpg",
      "images/projects/pa12.jpg",
      "images/projects/pa13.jpg",
      "images/projects/pa14.jpg",
      "images/projects/pa15.jpg",
      "images/projects/pa16.jpg",
      "images/projects/pa17.jpg",
      "images/projects/pa18.jpg",
      "images/projects/pa19.jpg",
      "images/projects/pa20.jpg",
      "images/projects/pa21.jpg",
    ],
    description:
      "රට තුල පවතින Covid-19 වසංගත තත්වය හමුවේ ජන ජීවිතයට එල්ල වූ බලපෑම් මත, ආදි ශිෂ්‍ය ශිෂ්‍යාවන්ගේ සහ පරිත්‍යාගශීලීන්ගේ ද දායකත්වයෙන් යටගල රජමහ විහාර දහම් පාසල අවට ග්‍රාමසේවා වසම් වල ජීවත් වන අඩු ආදායම්ලාභී පවුල් 220ක් වෙත වියලි ආහාර ද්‍රව්‍ය බෙදා දීමට හැකි විය...",
    path: "",
  },
  {
    title: '"අවැසි මොහොතේ නැවත අස්වැසිල්ලක් වූයෙමු" දෙවන අදියර',
    images: [
      "images/projects/pn1.jpg",
      "images/projects/pn2.jpg",
      "images/projects/pn3.jpg",
      "images/projects/pn4.jpg",
      "images/projects/pn5.jpg",
      "images/projects/pn6.jpg",
      "images/projects/pn7.jpg",
      "images/projects/pn8.jpg",
      "images/projects/pn9.jpg",
      "images/projects/pn10.jpg",
      "images/projects/pn11.jpg",
      "images/projects/pn12.jpg",
      "images/projects/pn13.jpg",
      "images/projects/pn14.jpg",
      "images/projects/pn15.jpg",
    ],
    description:
      "පවතින තත්වය හමුවේ සරණක් සොයන අපේම මිනිසුන් උදෙසා, ග්‍රාම සේවා වසම් හතක අඩු ආදායම්ලාභී පවුල් 160 ක් වෙනුවෙන් සංවිධානය වූ අපගේ සත්කාරය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015",
    images: [
      "images/projects/pb1.jpg",
      "images/projects/pb2.jpg",
      "images/projects/pb3.jpg",
      "images/projects/pb4.jpg",
      "images/projects/pb5.jpg",
      "images/projects/pb6.jpg",
      "images/projects/pb7.jpg",
      "images/projects/pb8.jpg",
      "images/projects/pb9.jpg",
      "images/projects/pb10.jpg",
      "images/projects/pb11.jpg",
      "images/projects/pb12.jpg",
    ],
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2015 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title: "යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014",
    images: [
      "images/projects/pc1.jpg",
      "images/projects/pc2.jpg",
      "images/projects/pc3.jpg",
      "images/projects/pc4.jpg",
      "images/projects/pc5.jpg",
      "images/projects/pc6.jpg",
      "images/projects/pc7.jpg",
      "images/projects/pc8.jpg",
      "images/projects/pc9.jpg",
    ],
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට යටගල රජමහ විහාර වෙසක් මහා දන්සල 2014 සදහා එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
  {
    title:
      "උතුම් වූ උපසම්පදාව ලැබ වැඩම වූ ගරුතර හිමිවරුන් දෙපළ වෙත දැක් වූ අපගේ පූජෝපහාරය",
    images: [
      "images/projects/pd1.jpg",
      "images/projects/pd2.jpg",
      "images/projects/pd3.jpg",
      "images/projects/pd4.jpg",
      "images/projects/pd5.jpg",
      "images/projects/pd6.jpg",
      "images/projects/pd7.jpg",
      "images/projects/pd8.jpg",
    ],
    description:
      "උපසම්පදා පුන්‍ය මහෝත්සවයේ උපසම්පදාලාභී ස්වාමීන් වහන්සේලා දෙපළ පිලිගැනීම වෙනුවෙන් අත්‍යවශ්‍යව පැවති පිලිගැනීමේ තොරණ ආදි ශිෂ්‍ය සංගමයේ අනුග්‍රහයෙන් නිර්මාණය කරන්නට අප හට හැකි වූයෙමු...දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    path: "",
  },
];

const upcomingEvents = [
  {
    title: "යටගල දහම් පාසල් සුභසාධක වෙළෙදසැල",
    images: ["images/IMG-20241005-WA0031.jpg"],
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      "යටගල රජමහ විහාර දහම් පාසල වෙත දහම් අධ්‍යාපනය හැදෑරීම සඳහා පැමිණෙන දරුවන් වෙනුවෙන් ඔවුන්ගේ සුභසාධනයට කටයුතු කිරීම අප මෙයින් ප්‍රධාන වශයෙන් බලාපොරොත්තු වේ. එහිදී දරුවන්ට අවශ්‍ය වන දහම් අධ්‍යාපනය සඳහා අවශ්‍ය වන අධ්‍යාපනික උපකරණ වෙළඳපල මිලටත් වඩා සහනදායී ආකාරයට දහම් පාසල් දරුවන්ට ලබා දීම මෙමඟින් සිදුවේ. මෙම කර්තව්‍ය මගින් විශාල ආර්ථික අපහසුතා ඇති දරුවන්ට පවා යම් සහනයක් ලබාදීම අපගේ ප්‍රධාන අරමුණ වේ. යටගල රජමහ විහාර දහම් පාසලට දහම් අධ්‍යාපනයට පැමිණෙන සියළු දුවා දරුවන් රැක බලා ගැනීම, ඔවුන් ගැන සොයා බැලීම අපගේ වගකීමක් ලෙස අප සිතන්නෙමු. එහිදී දහම් අධ්‍යාපනයට පැමිණෙන දරුවන්ට යම් ආර්ථික අපහසුතා ඇතිවන්නේ නම් එමගින් ඔවුන්ගේ අධ්‍යාපනයට දැඩි බාධාවක් සිදුවන්නේ නම් එය මගහරවා ගැනීමට, යම් දායකත්වයක් ලබාදීම අප මෙමගින් අපේක්ෂා කරන්නෙමු.",
    path: "",
  },
  {
    title: "යටගල රාජමහා විහාර වෙසක් මහා දන්සල",
    images: ["images/IMG-20241005-WA0031.jpg"],
    date: "Sep. 10, 2024",
    time: "10:30AM-03:30PM",
    venue: "Main Campus",
    description:
      'යටගල රාජමහා විහාරය යනු ශ්‍රී ලංකාව පුරා විසිරී පැතිරී සිටින බෞද්ධ ජනතාවගේ වන්දනාමානයට පත්වන ඓතිහාසික මෙන්ම ආකර්ශනීය පුදබිමකි. මෙම පුදබිම වසරේ සෑම පොහෝ දිනයකම බැතිමතුන්ගෙන් පිරී ඉතිරී යන අතර, විශේෂයෙන්ම වෙසක් පුන් පොහෝ දිනය යනු යටගල රාජමහා විහාරය අති විශාල බැතිමතුන් පිරිසකගෙන් පිරී ඉතිරී යන පොහොයකි. විහාරය වැඳ පුදා ගැනීමට දිවයිනේ නන් දෙසින් පැමිණෙන බැතිමතුන් වෙනුවෙන් වසර ගණනාවක් මුළුල්ලේ සිට අප විසින් සංවිධානය කරනු ලැබූ "යටගල රාජමහා විහාර වෙසක් මහා දන්සල" විශාල පිරිසකගේ කුසගිනි නිවන්නට ඉවහල් වූ සුවිශේෂී කටයුත්තකි. පසුගිය අවධියෙහි මෙරට සිදුවූ යම් යම් සිද්ධීන් හේතුවෙන් මෙම කටයුත්ත අපහට සිදුකිරීමට බාධා පැමිණි අතර මෙම මහගු පුණ්‍යකර්මය 2025 වර්ෂයේ වෙසක් පුන් පොහෝ දින සිට ඉදිරියට ක්‍රියාත්මක කිරීමට අප කටයුතු සූදානම් කර ඇත.',
    path: "",
  },
];

const blogs = [
  {
    title:
      "2024 වර්ෂයට සැමට සෙත් පතා පවත්වනු ලැබූ වාර්ෂික පහන් පූජා පිංකම සහ ආශිර්වාද පූජාව",
    images: [
      "images/projects/pm1.jpg",
      "images/projects/pm2.jpg",
      "images/projects/pm3.jpg",
      "images/projects/pm4.jpg",
      "images/projects/pm5.jpg",
      "images/projects/pm6.jpg",
      "images/projects/pm7.jpg",
      "images/projects/pm8.jpg",
      "images/projects/pm9.jpg",
      "images/projects/pm10.jpg",
      "images/projects/pm11.jpg",
      "images/projects/pm12.jpg",
      "images/projects/pm13.jpg",
      "images/projects/pm14.jpg",
      "images/projects/pm15.jpg",
      "images/projects/pm16.jpg",
      "images/projects/pm17.jpg",
      "images/projects/pm18.jpg",
      "images/projects/pm19.jpg",
      "images/projects/pm20.jpg",
      "images/projects/pm21.jpg",
    ],
    description:
      "දහම් පාසල වෙතින් ලබා ගත් පන්නරයේ ශක්තිය මෙන්ම විශිෂ්ඨත්වය විදහා දක්වන්නට එක් වූ ආදි ශිෂ්‍ය සැමට ප්‍රණාමය...",
    date: "Sep. 10, 2024",
  },
  {
    title:
      "යටගල රජමහ විහාර දහම් පාසල් ආදි සිසු එකමුතුවෙහි දෙනව මහා සභා රැස්වීම 2019",
    images: [
      "images/projects/ps1.jpg",
      "images/projects/ps2.jpg",
      "images/projects/ps3.jpg",
      "images/projects/ps4.jpg",
      "images/projects/ps5.jpg",
      "images/projects/ps6.jpg",
      "images/projects/ps7.jpg",
    ],
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක පෙර මග සලකුණු තබන්නට යුතුකම් ඉටු කරන්නට දම් පියස වෙත රොද බැඳි ඔබ සියළු දෙනා හට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම",
    images: [
      "images/projects/pf1.jpg",
      "images/projects/pf2.jpg",
      "images/projects/pf3.jpg",
      "images/projects/pf4.jpg",
    ],
    description:
      "ආදි ශිෂ්‍ය සංගමයේ 2020 වර්ෂයේ පලමු මහා සභා රැස්වීම... එකමුතුව ශක්තිමත් කරන්නට පැමිණි ඔබ සැමට ස්තූතියි...",
    date: "Sep. 10, 2024",
  },
  {
    title: "ආදි ශිෂ්‍ය සංගමය මහා සභා රැස්වීම සහ නිලවරණය - 2024",
    images: [
      "images/projects/pt1.jpg",
      "images/projects/pt2.jpg",
      "images/projects/pt3.jpg",
      "images/projects/pt4.jpg",
      "images/projects/pt5.jpg",
      "images/projects/pt6.jpg",
      "images/projects/pt7.jpg",
    ],
    description:
      "ශක්තිමත් ආදි ශිෂ්‍ය සංගමයක අභිමානය තව තවත් ශක්තිමත් කිරීමට, දම් පියස වෙත තම යුතුකම ඉටු කරන්නට පැමිණි සැමට ස්තූතියි.",
    date: "Sep. 10, 2024",
  },
];

const Article = () => {
  const { t } = useTranslation();
  const [article, setArticle] = useState({ name: "", index: 0 });
  const [images, setImages] = useState([]);

  const limitString = (str, limit) => {
    return str.length > limit ? str.slice(0, limit) + "..." : str;
  };

  const options = {
    items: 3,
    loop: true,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    console.log(projects[article.index].images);
    const timer = setTimeout(() => {
      setImages(projects[article.index].images);
    }, 3000);
  }, [article]);

  //   useEffect(() => {
  //     console.log(images);
  //   }, [images]);

  //   useEffect(() => {
  //     console.log(projects[article.index].images);
  //     setImages(projects[article.index].images);
  //   }, [localStorage.getItem("article")]);

  useEffect(() => {
    localStorage.setItem("page", "article");
    setArticle({
      name: localStorage.getItem("article"),
      index: localStorage.getItem("index"),
    });
    // console.log(localStorage.getItem("article"));
  }, []);
  return (
    <>
      <div
        class="hero-wrap"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('${
            projects[article.index].images[0]
          }')`,
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <Fade fraction={0.5} duration={2000}>
                <p
                  class="breadcrumbs"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  <span class="mr-2">
                    <a href="/" className="text-decoration-none">
                      {t("Home")}
                    </a>
                  </span>
                  {">"}
                  <span className="ms-2">{t("Article")}</span>
                </p>
              </Fade>
              <Fade fraction={0.5} duration={2000} delay={800}>
                <h1
                  class="mb-3 bread"
                  data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
                >
                  {t("Article")}
                </h1>
                {/* <p className="project-para">{t("AttractionsDesc")}</p> */}
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section ftco-gallery">
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="heading-section text-center article-title">
            <Zoom fraction={0.2} duration={2000}>
              <h2 className="mb-5 title-row">
                <img src={swirl} alt="swirl" />
                {article.name === "projects"
                  ? projects[article.index].title
                  : article.name === "blogs"
                  ? blogs[article.index].title
                  : ""}
                <img src={swirl} alt="swirl" />
              </h2>
            </Zoom>
          </div>
        </div>

        <div className="row w-100 m-auto">
          <Fade fraction={0.2} duration={2000}>
            <div className="col-md-12 d-flex">
              <OwlCarousel className="carousel-cause owl-carousel" {...options}>
                {projects[article.index].images.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={`${item}`}
                      className="gallery gallery-item image-popup d-flex justify-content-center align-items-center img m-auto"
                    >
                      <div
                        className="background-image"
                        style={{
                          backgroundImage: `url(${item})`,
                        }}
                      ></div>
                    </a>
                  );
                })}
                {/* {article.name === "projects" ? (
                  images.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={`${item}`}
                        className="gallery gallery-item image-popup d-flex justify-content-center align-items-center img m-auto"
                      >
                        <div
                          className="background-image"
                          style={{
                            backgroundImage: `url(${item})`,
                          }}
                        ></div>
                      </a>
                    );
                  })
                ) : article.name === "blogs" ? (
                  blogs[article.index].images.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={`${item}`}
                        className="gallery gallery-item image-popup d-flex justify-content-center align-items-center img m-auto"
                      >
                        <div
                          className="background-image"
                          style={{
                            backgroundImage: `url(${item})`,
                          }}
                        ></div>
                      </a>
                    );
                  })
                ) : (
                  <></>
                )} */}
              </OwlCarousel>
            </div>
          </Fade>
        </div>
      </section>

      {/* <section className="ftco-section ftco-gallery">
        <div className="d-flex p-0 m-0 gallery-grid align-items-center justify-content-center">
          {images.map((image, index) => {
            return (
              <Zoom fraction={0.5} duration={500}>
                <a
                  key={index}
                  href={`${image}`}
                  className="gallery gallery-item image-popup d-flex justify-content-center align-items-center img"
                >
                  <div
                    className="background-image"
                    style={{ backgroundImage: `url(${image})` }}
                  ></div>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="icon-search"></span>
                  </div>
                </a>
              </Zoom>
            );
          })}
        </div>
      </section> */}

      <section className="ftco-section mt-0 pt-0">
        <p className="px-2">{projects[article.index].description}</p>
      </section>
    </>
  );
};

export default Article;
